import { ReactElement, useMemo, useRef, useState } from "react";
import { Box, BoxProps, Flex, Heading, Image, Link } from "@chakra-ui/react";
import { NavLink, useLocation } from "react-router-dom";
import { IPodcast, IPodcastCarousel } from "../redux/types";
import strings from "../../../locale/localization";
import { PodmeColor, StructuredDataContent } from "@typings/index";
import { useCarousel, useMedia } from "app/hooks";
import CarouselButton from "./CarouselButton";
import { carouselElementsGroup } from "../utils";
import { SectionProps } from "@components/Cms/CmsSections";
import { Helmet } from "react-helmet";
import useStructuredData from "app/hooks/useStructuredData";
import { isEmpty } from "lodash";
import { uid } from "@utils/index";

interface Props {
    sectionContent: IPodcastCarousel;
    cardSize: number[];
}

export default function PodcastCarousel({ sectionContent, cardSize, testId, ...rest }: Props & SectionProps & BoxProps): ReactElement {
    const { locale, podcast } = strings.routes;
    const { isSmallScreen } = useMedia();
    const { pathname } = useLocation();

    const [carouselHover, setCarouselHover] = useState<boolean>(false);

    const carouselRef = useRef<HTMLDivElement | null>(null);

    const CAROUSEL_STEP = 1;
    const CAROUSEL_ELEMENTS_GAP = 16;
    const CAROUSEL_ELEMENT_WIDTH = Number(isSmallScreen ? cardSize[0] : cardSize[1]);

    const { handleCarouselMove, bind, isFirstElementVisible, isLastElementVisible } = useCarousel(
        carouselRef,
        CAROUSEL_ELEMENT_WIDTH,
        CAROUSEL_ELEMENTS_GAP,
        CAROUSEL_STEP
    );

    const carouselElements = carouselElementsGroup(sectionContent.podcasts, CAROUSEL_STEP);

    const structuredData = useStructuredData(StructuredDataContent.PodcastList, {
        url: pathname,
        podcastSection: sectionContent as IPodcastCarousel,
    });

    const structuredDataKey = useMemo(() => uid(), []);

    return (
        <>
            <Helmet>
                {!isEmpty(structuredData) && (
                    <script type="application/ld+json" key={structuredDataKey}>
                        {JSON.stringify(structuredData)}
                    </script>
                )}
            </Helmet>
            <Box
                position="relative"
                data-testid={testId}
                onMouseEnter={() => setCarouselHover(true)}
                onMouseLeave={() => setCarouselHover(false)}
                {...rest}
            >
                <Flex
                    gridGap={4}
                    overflowX="scroll"
                    padding={["1rem", "1rem 2rem"]}
                    ref={carouselRef}
                    {...bind()}
                    style={{
                        scrollSnapType: "x mandatory",
                        scrollPaddingInline: isSmallScreen ? "1rem" : "2rem",
                        overscrollBehaviorX: "contain",
                    }}
                    css={{
                        "&::-webkit-scrollbar": {
                            display: "none",
                        },
                    }}
                >
                    <CarouselButton
                        visible={!isFirstElementVisible && carouselHover && !isSmallScreen}
                        direction="left"
                        carouselElWidth={CAROUSEL_ELEMENT_WIDTH}
                        handleCarouselMove={() => handleCarouselMove("left")}
                    />
                    {carouselElements.map((group, groupIdx) => (
                        <Flex
                            key={groupIdx}
                            gridGap={4}
                            style={{
                                scrollSnapAlign: "start",
                            }}
                        >
                            {group.map((el: Partial<IPodcast>) => (
                                <Link
                                    as={NavLink}
                                    to={`/${locale}/${podcast}/${el.destinationPath}`}
                                    key={el.id}
                                    _hover={{ textDecoration: "none", transform: "scale(1.07)" }}
                                >
                                    <Flex flexDirection="column" gridGap={"0.5rem"}>
                                        <Image
                                            loading="lazy"
                                            backgroundColor={PodmeColor.LicoriceBlue}
                                            src={el.imageUrl}
                                            boxSize={cardSize}
                                            minWidth={cardSize}
                                            borderRadius="1rem"
                                            alt={el.title}
                                            boxShadow={`0 0 0 1px ${PodmeColor.Silver}20`}
                                        />
                                        <Heading
                                            as="h3"
                                            fontWeight="100"
                                            color={PodmeColor.Silver}
                                            fontSize="0.875rem"
                                            maxWidth={cardSize}
                                            noOfLines={2}
                                        >
                                            {el.title}
                                        </Heading>
                                    </Flex>
                                </Link>
                            ))}
                        </Flex>
                    ))}
                    <CarouselButton
                        visible={!isLastElementVisible && carouselHover && !isSmallScreen}
                        direction="right"
                        carouselElWidth={CAROUSEL_ELEMENT_WIDTH}
                        handleCarouselMove={() => handleCarouselMove("right")}
                    />
                </Flex>
            </Box>
        </>
    );
}
