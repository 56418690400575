import { ReactElement, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router";
import Cookies from "universal-cookie";
import { ThemeProvider, CSSReset } from "@chakra-ui/react";
import { polyfill as smoothScrollPolyfill } from "seamless-scroll-polyfill";
import { Location } from "history";
import Layout from "./Layout/Layout";
import InfoMessage from "../components/InfoMessage/InfoMessage";
import { GET_REGION_INFO, SET_EXPERIMENT_VARIABLES } from "../actions/actionTypes";
import {
    ENABLED_CONTENT_PREVIEW,
    GET_CMS_CONTENT,
    GetCmsContent,
    GET_CMS_CONTENT_SUCCESS,
    GET_CMS_CONTENT_FAILED,
    GET_FOOTER_PAGES_CONTENT,
    GET_FOOTER_PAGES_CONTENT_FAILED,
    GET_FOOTER_PAGES_CONTENT_SUCCESS,
} from "../content/contentTypes";

import {
    StartPage,
    InfoPage,
    PageNotFound,
    MyPodcastsPage,
    SearchPage,
    SettingsPage,
    LoginPage,
    SignupPage,
    PremiumConfirmationPage,
    PodcastPage,
    Checkout,
    EpisodePage,
    Homescreen,
    HomescreenAuth,
    CategoriesPage,
    CategoryPage,
    BundlerRoutes,
    StudentLandingPageHitract,
    StudentRoutes,
    ManageSubscriptionsPage,
    MigrationPage,
    Transaction,
    LoginAppFlowPage,
    LoginOpenAppPage,
} from "./routes";

import PrivateRoute from "../components/PrivateRoute";

import strings from "@locale/localization";
import customTheme from "../config/customTheme";
import { RootState } from "../reducers/rootReducer";
import useAuth from "../auth/useAuth";
import { ActionWithAsyncResult, Region } from "@typings/index";
import { MigrationModal } from "@containers/Migration";
import { useSubscriptionQuery } from "@api/hooks/subscription";

import "../styles/main.scss";

import CmsPreviewPage from "./CmsPreviewPage";
import { IdentityProvider } from "@auth/authTypes";
import PremiumPage from "./PremiumPage";
import BlockedAccountModal from "@components/BlockedAccountModal/BlockedAccountModal";
import useEnvoySharing from "app/hooks/useEnvoySharing";
import { STUDENT_BASE_PATH } from "./Student/constants";
import { SchibstedLoginOverlay } from "@components/SchibstedLoginOverlay/SchibstedLoginOverlay";
import useLoginWidget from "app/hooks/useLoginWidget";

interface Props {
    windowObj?: any;
}

const globalState = (state: RootState) => state.global;
const contentState = (state: RootState) => state.content;

const TOKEN_REFRESHING_INTERVAL = 1000 * 60 * 8; //8 min

function Root({ windowObj }: Props): ReactElement {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const { content, footerPages } = useSelector(contentState);
    const { lang, region, variables } = useSelector(globalState);
    const { isAuthenticated, refreshToken, loginProvider, showRedirectOverlay } = useAuth();

    useLoginWidget();
    useEnvoySharing();

    const searchParams = new URLSearchParams(location.search);
    const isPreview = useMemo(() => {
        return searchParams.get("isPreview");
    }, [searchParams]);

    const cookie = new Cookies();
    const experiment = windowObj.experiment;

    useSubscriptionQuery(isAuthenticated);

    if (lang) {
        strings.setLanguage(lang);
    }

    const {
        discover,
        myPodcasts,
        forPodcasters,
        aboutPodme,
        contact,
        questionsAndAnswers,
        iosFaq,
        cookies,
        userTerms,
        privacyPolicy,
        search,
        dashboard,
        payments,
        transaction,
        locale,
        partners,
        login,
        signup,
        congrats,
        categories,
        subscriptions,
        podcast,
        episode,
        changeSubscription,
    } = strings.routes;

    useMemo(() => {
        if (!content || (content && content.region !== locale) || isPreview) {
            dispatch<ActionWithAsyncResult<GetCmsContent>>({
                type: GET_CMS_CONTENT,
                region: locale as Region,
                WAIT_FOR_ACTION: [GET_CMS_CONTENT_SUCCESS, GET_CMS_CONTENT_FAILED],
            });
        }
        let currentLocation = location.pathname;

        if (region && currentLocation.includes("/se/")) {
            if (region.toLowerCase() === Region.Norway) {
                currentLocation = currentLocation.replace("/se/", "/no/");
                document.location.pathname = currentLocation;
            }
            if (region.toLowerCase() === Region.Finland) {
                currentLocation = currentLocation.replace("/se/", "/fi/");
                document.location.pathname = currentLocation;
            }
        }
    }, [content?.region, region, locale, isPreview]);

    useEffect(() => {
        smoothScrollPolyfill();

        const isRegionSet = cookie.get("region");

        if (!isRegionSet) {
            dispatch({ type: GET_REGION_INFO });
        }

        if (isPreview) {
            dispatch({ type: ENABLED_CONTENT_PREVIEW });
        }

        const unlisten = history.listen(({ state }: Location<any>) => {
            if (state?.scrollToTop === false) return;
            setTimeout(() => {
                typeof window !== "undefined" && window.scrollTo(0, 0);
            }, 100);
        });

        return () => unlisten();
    }, []);

    useEffect(() => {
        if (isAuthenticated && loginProvider === IdentityProvider.Schibsted) {
            const interval = setInterval(() => {
                refreshToken();
            }, TOKEN_REFRESHING_INTERVAL);
            return () => clearInterval(interval);
        }
    }, [isAuthenticated, loginProvider]);

    useEffect(() => {
        if (experiment) {
            dispatch({ type: SET_EXPERIMENT_VARIABLES, value: experiment });
        }
    }, [experiment]);

    useMemo(() => {
        if (!footerPages) {
            dispatch({
                type: GET_FOOTER_PAGES_CONTENT,
                locale,
                WAIT_FOR_ACTION: [GET_FOOTER_PAGES_CONTENT_SUCCESS, GET_FOOTER_PAGES_CONTENT_FAILED],
            });
        }
    }, [footerPages, locale]);

    return (
        <>
            <ThemeProvider theme={customTheme}>
                <CSSReset />
                {variables.maintenance_active && <InfoMessage />}
                <Layout>
                    <>
                        <Switch>
                            <Route exact path={`/${locale}/cms/preview`} component={CmsPreviewPage} />

                            <Route exact path={`/${locale}/${login}`} component={LoginPage} />
                            <Route exact path={`/${locale}/login-app`} component={LoginAppFlowPage} />
                            <Route exact path={`/${locale}/login-app/idp`} component={LoginOpenAppPage} />
                            <Route exact path={`/${locale}/login-app-flow`}>
                                <LoginPage redirectLoginToMobile />
                            </Route>

                            <PrivateRoute exact path={`/${locale}/${signup}/checkout`} isCheckout component={Checkout} />
                            <Route path={`/${locale}/${signup}`} component={SignupPage} />

                            <Route exact path={`/${locale}/${iosFaq}`} component={InfoPage} />
                            <Route exact path={`/${locale}/${questionsAndAnswers}`} component={InfoPage} />
                            <Route exact path={`/${locale}/schibsted-faq`} component={InfoPage} />
                            <Route exact path={`/${locale}/${cookies}`} component={InfoPage} />
                            <Route exact path={`/${locale}/${userTerms}`} component={InfoPage} />
                            <Route exact path={`/${locale}/${privacyPolicy}`} component={InfoPage} />
                            <Route exact path={`/${locale}/privacypolicy`} component={InfoPage} />
                            <Route exact path={`/${locale}/${forPodcasters}`} component={InfoPage} />
                            <Route exact path={`/${locale}/${aboutPodme}`} component={InfoPage} />
                            <Route exact path={`/${locale}/${contact}`} component={InfoPage} />
                            <Route exact path={`/${locale}/${partners}`} component={InfoPage} />

                            <PrivateRoute exact path={`/${locale}/${congrats}`} component={PremiumConfirmationPage} />

                            <Route exact path={`/${locale}/${categories}`} component={CategoriesPage} />
                            <Route exact path={`/${locale}/${categories}/:categoryId/:categoryName`} component={CategoryPage} />

                            <Route exact path={`/${locale}/${discover}`} component={Homescreen} />
                            <PrivateRoute exact path={`/${locale}/auth/${discover}`} component={HomescreenAuth} />

                            <Route path={`/${locale}/partner`}>
                                <BundlerRoutes />
                            </Route>
                            <Route path={STUDENT_BASE_PATH(locale)}>
                                <StudentRoutes />
                            </Route>

                            <Route exact path={`/${locale}/hitract`} component={StudentLandingPageHitract} />
                            <Route exact path={`/${locale}/migration`} component={MigrationPage} />
                            <PrivateRoute
                                exact
                                path={`/${locale}/${dashboard}/${subscriptions}/${changeSubscription}`}
                                component={ManageSubscriptionsPage}
                            />

                            <PrivateRoute
                                exact
                                path={`/${locale}/${dashboard}/${payments}/${transaction}/:id`}
                                component={Transaction}
                            />
                            <PrivateRoute path={`/${locale}/${dashboard}`} component={SettingsPage} />

                            <PrivateRoute exact path={`/${locale}/${myPodcasts}`} component={MyPodcastsPage} />
                            <Route exact path={`/${locale}/${search}`} component={SearchPage} />

                            <Route exact path={`/${locale}/premium`} component={PremiumPage} />

                            <Redirect exact from={`/${locale}/start`} to={`/${locale}/`} />

                            <Redirect exact from={`/${locale}/:slug`} to={`/${locale}/${podcast}/:slug`} />
                            <Route exact path={`/${locale}/${podcast}/:slug`} component={PodcastPage} />

                            <Route exact path={`/${locale}/${episode}/:id`} component={EpisodePage} />
                            <Redirect exact from={`/${locale}/:slug/:id`} to={`/${locale}/${episode}/:id`} />
                            <Redirect exact from={`/${locale}/${podcast}/:slug/:id`} to={`/${locale}/${episode}/:id`} />

                            <Route exact path={`/${locale}/`} component={StartPage} />

                            <Route component={PageNotFound} />
                        </Switch>
                        <BlockedAccountModal />
                        <MigrationModal />
                        {showRedirectOverlay && <SchibstedLoginOverlay />}
                    </>
                </Layout>
            </ThemeProvider>
        </>
    );
}

export default Root;
