import { useMedia } from "app/hooks";
import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import strings from "@locale/localization";
import { Category, CSSstylesObject, DeviceBreakpoints, PodmeColor } from "@typings/index";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import TagList from "@containers/PodcastPage/components/TagList";

interface ICategory {
    id: number;
    isPrimary: boolean;
    key: string;
    name: string;
}

interface IProps {
    title: string;
    description: string;
    destinationPath: string | null;
    categories: ICategory[] | Category[];
    onHomescreen: boolean;
    withBackground?: boolean;
}

export const styles: CSSstylesObject = {
    categoriesChipsWrapper: {
        fontSize: "0.7rem",
        paddingBottom: "1rem",
        display: "flex",
        gridGap: "0.7rem",
        overflowX: "scroll",
        maxWidth: "100%",
        alignItems: "center",
    },
    categoryChip: {
        padding: "0.5rem 1rem",
        borderRadius: "16px",
        background: PodmeColor.LicoriceBlue,
        color: PodmeColor.SoftWhite,
        textAlign: "center",
        whiteSpace: "nowrap",
    },
    premiumChip: {
        padding: "0.5rem 1rem",
        borderRadius: "16px",
        background: PodmeColor.Cyan,
        color: PodmeColor.BlackPearl,
    },
    elementDataWrapper: {
        // paddingLeft: "4rem",
        flexDirection: "column",
        // padding: ["0 1rem", 0],
        gridGap: "0.5rem",
        sx: {
            [`@media ${DeviceBreakpoints.MediumDown}`]: {
                height: "unset",
                // paddingLeft: "5.5rem",
                // paddingRight: "5.5rem",
            },
            [`@media ${DeviceBreakpoints.SmallOnly}`]: {
                // padding: "0.5rem",
            },
        },
    },
    elementDataDescription: {
        maxWidth: "600px",
        fontSize: "1rem",
        overflowY: "scroll",
        color: PodmeColor.Silver,
        paddingLeft: "2rem",
        sx: {
            [`@media ${DeviceBreakpoints.MediumDown}`]: {
                fontSize: "1rem",
                paddingBottom: "1rem",
                height: "unset",
                maxWidth: "500px",
            },
            "&::-webkit-scrollbar": {
                width: "0.5rem",
                transition: "all 150ms ease-in",
            },

            "&::-webkit-scrollbar-track": {
                boxShadow: `inset 0 0 6px ${PodmeColor.BlackPearl}30`,
            },

            "&::-webkit-scrollbar-thumb": {
                backgroundColor: PodmeColor.LicoriceBlue,
                borderRadius: "0.75rem",
            },

            "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: PodmeColor.LicoriceBlue + "80",
            },
        },
    },
    elementDataTitle: {
        fontSize: "24px",
        padding: "0 !important",
        noOfLines: 2,
        transition: "opacity 75ms ease-in",
    },
};

const InfoSection: FC<IProps> = ({ title, description, destinationPath, categories, onHomescreen, withBackground = false }) => {
    const { locale, podcast, episode } = strings.routes;
    const { isLargeScreen } = useMedia();
    const lastDestinationPathSegment = destinationPath?.split("/")[1];
    const isEpisodePath = !isNaN(Number(lastDestinationPathSegment));

    const url = destinationPath
        ? isEpisodePath
            ? `/${locale}/${episode}/${lastDestinationPathSegment}/`
            : `/${locale}/${podcast}/${destinationPath}/`
        : "";

    return destinationPath ? (
        <Link to={url}>
            <Flex
                {...styles.elementDataWrapper}
                gridGap="1rem"
                position="relative"
                isolation="isolate"
                _hover={{
                    "&::before": {
                        opacity: "1",
                    },
                }}
                padding={withBackground && "1rem"}
                _before={{
                    display: "block",
                    content: "''",
                    position: "absolute",
                    inset: withBackground ? "0" : "-1rem",
                    bgColor: `${PodmeColor.Silver}10`,
                    zIndex: "-1",
                    rounded: "1rem",
                    opacity: withBackground ? "1" : "0",
                    transition: "opacity 100ms ease-in",
                }}
            >
                <Flex direction="column" gridGap="0.5rem">
                    <Flex alignItems="flex-end">
                        <Heading {...styles.elementDataTitle}>{`${title.charAt(0).toUpperCase()}${title.slice(1)}`}</Heading>
                    </Flex>
                    {/* Categories chips are displayed below the carousel on Tablet and Mobile */}
                    {!isLargeScreen && !onHomescreen && <TagList categories={categories} />}
                </Flex>
                <Text {...styles.elementDataDescription} noOfLines={3} maxWidth="100% !important" p="0 !important">
                    {description}
                </Text>
                {/* NO TRAILERS TO PLAY FOR NOW */}
                {/* {!isLargeScreen && <PlaySampleButton isPlaying={isPlaying} setIsPlaying={setIsPlaying} />} */}
            </Flex>
        </Link>
    ) : (
        <Flex {...styles.elementDataWrapper}>
            <Flex direction="column" gridGap="0.5rem">
                <Flex alignItems="flex-end">
                    <Heading {...styles.elementDataTitle}>{`${title.charAt(0).toUpperCase()}${title.slice(1)}`}</Heading>
                </Flex>
                {/* Categories chips are displayed below the carousel on Tablet and Mobile */}
                {!isLargeScreen && !onHomescreen && <TagList categories={categories} />}
            </Flex>
            {/* Categories chips are displayed below the carousel on Tablet and Mobile */}
            {!isLargeScreen && !onHomescreen && (
                <Box {...styles.categoriesChipsWrapper} className="hide-scrollbar">
                    <TagList categories={categories} />
                </Box>
            )}
            <Text {...styles.elementDataDescription} noOfLines={3} maxWidth="100% !important" p="0 !important">
                {description}
            </Text>
        </Flex>
    );
};

export default InfoSection;
