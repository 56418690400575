import { ReactElement, useMemo, useRef, useState } from "react";
import { Flex, Heading, Text, Box, Grid } from "@chakra-ui/react";
import { IEpisode, IPodcastNuggets } from "../redux/types";
import { PodmeColor, StructuredDataContent } from "@typings/index";
import PremiumBadge from "@components/PremiumBadge";
import strings from "@locale/localization";
import { useMedia, useCarousel, useStructuredData } from "app/hooks";
import CarouselButton from "./CarouselButton";
import { Helmet } from "react-helmet";
import config from "app/config";
import { useLocation } from "react-router";
import { isEmpty } from "lodash";
import { uid } from "@utils/index";
import ListItemRow, { LIST_ITEM_ROW_TYPE } from "./ListItemRow";

interface Props {
    content: IPodcastNuggets;
}

export default function PodcastNuggets({ content }: Props): ReactElement {
    const { isSmallScreen } = useMedia();
    const { pathname } = useLocation();

    const [carouselHover, setCarouselHover] = useState<boolean>(false);

    const carouselRef = useRef<HTMLDivElement | null>(null);

    const CAROUSEL_STEP = 1;
    const CAROUSEL_ELEMENTS_GAP = 16;
    const CAROUSEL_ELEMENT_WIDTH = isSmallScreen ? 400 : 600;

    const { handleCarouselMove, bind, isFirstElementVisible, isLastElementVisible } = useCarousel(
        carouselRef,
        CAROUSEL_ELEMENT_WIDTH,
        CAROUSEL_ELEMENTS_GAP,
        CAROUSEL_STEP
    );

    const structuredData = useStructuredData(StructuredDataContent.EpisodeList, {
        url: `${config.PUBLIC_URL}${pathname}`,
        episodeSection: content,
    });

    const structuredDataKey = useMemo(() => uid(), []);

    return (
        <>
            <Helmet>
                {!isEmpty(structuredData) && (
                    <script type="application/ld+json" key={structuredDataKey}>
                        {JSON.stringify(structuredData)}
                    </script>
                )}
            </Helmet>
            <Flex direction="column" gridGap={"1rem"} position="relative">
                <Flex direction="column" gridGap="0.25rem" padding={["0 1rem", "0 2rem"]}>
                    <Text as="span" color={PodmeColor.Silver} fontSize="0.875rem">
                        {strings.podcastPage.listenTo}
                    </Text>
                    <Heading p="0 !important" as="h2" fontSize={["1.125rem", "1.5rem", "1.75rem"]}>
                        {content.title}
                    </Heading>
                </Flex>
                <Box position="relative" onMouseEnter={() => setCarouselHover(true)} onMouseLeave={() => setCarouselHover(false)}>
                    <Flex
                        padding={["0 1rem", "0 2rem"]}
                        overflowX="scroll"
                        style={{
                            scrollSnapType: "inline mandatory",
                            scrollPaddingInline: isSmallScreen ? "1rem" : "2rem",
                            overscrollBehaviorInline: "contain",
                        }}
                        {...bind()}
                        ref={carouselRef}
                        css={{
                            "&::-webkit-scrollbar": {
                                display: "none",
                            },
                        }}
                    >
                        <CarouselButton
                            visible={!isFirstElementVisible && carouselHover && !isSmallScreen}
                            direction="left"
                            carouselElWidth={430}
                            handleCarouselMove={() => handleCarouselMove("left")}
                        />
                        <Flex gridGap={4} zIndex="10" position="relative" transition="all 150ms ease-in">
                            {content.episodeLists.map((list, idx) => (
                                <Box
                                    key={list.title}
                                    style={{
                                        scrollSnapAlign: "start",
                                    }}
                                >
                                    <PodcastNugget
                                        title={list.title}
                                        isPremium={true}
                                        listOfEpisodes={list.episodes}
                                        width={
                                            idx === content.episodeLists.length - 1
                                                ? ["calc(100vw - 32px)", CAROUSEL_ELEMENT_WIDTH + "px"]
                                                : ["calc(100vw - 40px)", CAROUSEL_ELEMENT_WIDTH + "px"]
                                        }
                                    />
                                </Box>
                            ))}
                        </Flex>
                        <CarouselButton
                            visible={!isLastElementVisible && carouselHover && !isSmallScreen}
                            direction="right"
                            carouselElWidth={430}
                            handleCarouselMove={() => handleCarouselMove("right")}
                        />
                    </Flex>
                </Box>
            </Flex>
        </>
    );
}

interface PodcastNuggetProps {
    title: string;
    isPremium: boolean;
    listOfEpisodes: IEpisode[];
    width: string[];
}

function PodcastNugget({ title, isPremium, listOfEpisodes, width }: PodcastNuggetProps) {
    return (
        <Flex flexDirection="column" gridGap={2} width={width} height="100%">
            <Flex gridGap={2} alignItems="center">
                {isPremium && (
                    <>
                        <PremiumBadge width="4rem" flexShrink="0" />
                        <Box as="span" height="0.75rem" width="2px" backgroundColor={PodmeColor.Silver} />
                    </>
                )}
                <Heading as="h3" fontSize="14px" fontWeight="400" color={PodmeColor.Silver} noOfLines={1}>
                    {title}
                </Heading>
            </Flex>
            <Grid autoRows="1fr" gridGap={4} height="100%">
                {listOfEpisodes.map((episode: IEpisode, idx: number) => (
                    <ListItemRow item={episode} key={idx} type={LIST_ITEM_ROW_TYPE.EPISODE} />
                ))}
            </Grid>
        </Flex>
    );
}
