import { Flex, Heading, Image } from "@chakra-ui/react";
import Skeleton from "@components/Skeleton";
import { ObjectValues, PodmeColor } from "@typings/index";
import strings from "app/locale/localization";
import { memo, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { IEpisode, IPodcast } from "../redux/types";

export const CAROUSEL_SQUARE_CARD_TYPE = {
    PODCAST: "PODCAST",
    EPISODE: "EPISODE",
} as const;
type CarouselSquareCardType = ObjectValues<typeof CAROUSEL_SQUARE_CARD_TYPE>;
interface IProps {
    itemData: IPodcast | IEpisode;
    cardSize: number[];
    type?: CarouselSquareCardType;
}

function CarouselSquareCard({ itemData, cardSize, type }: IProps) {
    const { locale, podcast, episode } = strings.routes;

    const destinationLink = useMemo(() => {
        if (type === CAROUSEL_SQUARE_CARD_TYPE.EPISODE) return `/${locale}/${episode}/${(itemData as IEpisode)?.id}`;
        if (type === CAROUSEL_SQUARE_CARD_TYPE.PODCAST) return `/${locale}/${podcast}/${(itemData as IPodcast)?.slug}`;
        return `/${locale}/${podcast}/${itemData?.destinationPath}`;
    }, [itemData]);

    const [imageLoaded, setImageLoaded] = useState<boolean>(false);

    useEffect(() => {
        // onLoad is called when the image is loaded, but if the image is cached, it won't be called
        // so we need to check if the image is cached and if it is, we need to set isImageLoaded to true manually
        const matchingEntry = window.performance
            .getEntries()
            .filter((entry) => entry.name.includes(itemData?.imageUrl ?? ""))[0] as PerformanceResourceTiming;

        if (matchingEntry && matchingEntry.transferSize === 0) {
            setImageLoaded(true);
        }
    }, []);

    return (
        <Skeleton
            isLoaded={typeof window === "undefined" ? true : imageLoaded}
            width={cardSize}
            height={imageLoaded ? "100%" : cardSize}
            rounded="1rem"
        >
            <Link to={destinationLink}>
                <Flex
                    flexDirection="column"
                    gridGap={"0.5rem"}
                    _hover={{ textDecoration: "none", transform: "scale(1.07)" }}
                    transition="100ms ease-in"
                >
                    <Image
                        loading="lazy"
                        backgroundColor={PodmeColor.LicoriceBlue}
                        src={itemData?.imageUrl ?? ""}
                        boxSize={cardSize}
                        minWidth={cardSize}
                        borderRadius="1rem"
                        alt={itemData?.title ?? ""}
                        boxShadow={`0 0 0 1px ${PodmeColor.Silver}20`}
                        onLoad={() => setImageLoaded(true)}
                        onError={() => setImageLoaded(true)}
                    />
                    <Heading as="h3" fontWeight="100" color={PodmeColor.Silver} fontSize="0.875rem" maxWidth={cardSize} noOfLines={2}>
                        {itemData?.title ?? ""}
                    </Heading>
                </Flex>
            </Link>
        </Skeleton>
    );
}

export default memo(CarouselSquareCard);
