import { Episode, Podcast, Region } from "@typings/index";
import {
    ContentBriefingType,
    ExplorerPodcastCategory,
    FaqContent,
    PodcastsMainCarousel,
    PricingContent,
    TextContentSection,
} from "app/content/contentTypes";
import { ValuePropositionContent } from "app/content/contentTypes";

export const GET_CONTENT = "homescreen/GET_CONTENT";
export const GET_CONTENT_SUCCESS = "homescreen/GET_CONTENT_SUCCESS";
export const GET_CONTENT_FAILED = "homescreen/GET_CONTENT_FAILED";

export const GET_PREVIEW_CONTENT = "homescreen/GET_PREVIEW_CONTENT";
export const GET_PREVIEW_CONTENT_SUCCESS = "homescreen/GET_PREVIEW_CONTENT_SUCCESS";
export const GET_PREVIEW_CONTENT_FAILED = "homescreen/GET_PREVIEW_CONTENT_FAILED";

export enum ContentType {
    ListOfHeroCards = "listOfHeroCards",
    ListOfEpisodes = "listOfEpisodes",
    ListOfPodcasts = "listOfPodcasts",
    EpisodeCarousel = "episodeCarousel",
    BannerLink = "bannerLink",
    PodcastNuggets = "podcastNuggets",
    EpisodePromoCarousel = "episodePromoCarousel",
    PremiumPromo = "premiumPromo",
    DynamicContent = "dynamicContent",
    BannerWithEpisodeList = "bannerWithEpisodeList",
    BannerWithPodcastLink = "bannerWithPodcastLink",
    PodcastCarousel = "podcastCarousel",
    PodcastCarouselNew = "podcastCarouselNew",
    PodcastPromoCarousel = "podcastPromoCarousel",
    HeroSection = "heroSectionWeb",
    ContentBriefing = "contentBriefing",
    MainCarousel = "podcastCarousel",
    ValueProposition = "valueProposition",
    ExploreCategoriesSection = "webCategoriesExplorer",
    Pricing = "pricing",
    Faq = "faq",
    TextContent = "textContent",
    LandingHero = "landingHero",
}

export interface HomescreenState {
    sections: Section[];
    isFetching: boolean;
}

export interface GetContentResponse {
    sections: Section[];
}

export type SectionContent =
    | IListOfHeroCards
    | IListOfEpisodes
    | IListOfPodcasts
    | IPodcastCarousel
    | IEpisodeCarousel
    | IPodcastNuggets
    | IEpisodePromoCarousel
    | IBannerWithEpisodeList
    | IBannerWithPodcastLink
    | IPremiumPromo
    | IPodcastPromoCarousel
    | ContentBriefingType
    | ValuePropositionContent
    | PodcastsMainCarousel
    | PricingContent
    | FaqContent
    | TextContentSection
    | LandingHeroContent
    | ExploreCategoriesSectionContent;

export interface Section {
    content: SectionContent;
}

interface Content {
    title: string;
    type: ContentType;
}

export interface HeroCard {
    destinationPath: string;
    destination: string;
    episodeData: IEpisodeData[];
    hasPodcastBookmark: boolean;
    imageUrl: string;
    isPlayable: true;
    mainHeader: string;
    podcastId: number;
    shortDescription: string;
    subHeader: string | null;
    type: "episode" | "podcast";
}

export interface CTABtn {
    type: "cta";
    text: string;
    slug: string;
    coupon?: string;
}

export interface IListOfHeroCards extends Content {
    heroCards: HeroCard[];
}

export interface IListOfEpisodes extends Content {
    episodes: IEpisode[];
}
export interface IListOfPodcasts extends Content {
    podcasts: IPodcast[];
}

export interface IPodcastCarousel extends Content {
    podcasts: Podcast[];
}

export interface IEpisodeCarousel extends Content {
    episodes: Episode[];
}

export interface IPodcastNuggets extends Content {
    subtitle: string;
    episodeLists: { type: string; title: string; episodes: IEpisode[] }[];
}

export interface IEpisodePromoCarousel extends Content {
    isToplist: boolean;
    promotedEpisodes: IEpisode[];
}

export interface IPodcastPromoCarousel extends Content {
    isToplist: boolean;
    promotedPodcasts: IPodcast[];
}

export interface IBannerWithEpisodeList extends Content {
    imageUrl: string;
    episodes: IEpisode[];
    hideTitle: boolean;
    description: string;
    type: ContentType.BannerWithEpisodeList;
}

export interface IBannerWithPodcastLink extends Content {
    description: string;
    type: ContentType.BannerWithPodcastLink;
    podcastSlug: string;
    hideTitle: boolean;
    imageUrl: string;
}

export interface IPremiumPromo extends Content {
    titleBoldSection: string;
    buttonText: string;
    disclaimer: string;
    podcasts: IPodcast[];
}

export interface IBannerLink extends Content {
    description: string;
    imageUrl: string;
    title: string;
}

export interface LandingHeroContent extends Content {
    headline: string;
    lead: string;
    cta: CTABtn;
}

export interface ExploreCategoriesSectionContent extends Content {
    headline: string;
    subheadline: string;
    cta: CTABtn;
    categories: ExplorerPodcastCategory[];
}

export interface GetContent {
    type: typeof GET_CONTENT;
    isAuthenticated: boolean;
}

export interface GetContentSuccess {
    type: typeof GET_CONTENT_SUCCESS;
    content: GetContentResponse;
}

export interface GetContentFailed {
    type: typeof GET_CONTENT_FAILED;
    err: any;
}

export interface GetPreviewContent {
    type: typeof GET_PREVIEW_CONTENT;
    region: Region;
    hasSubscription: boolean;
}

export interface GetPreviewContentSuccess {
    type: typeof GET_PREVIEW_CONTENT_SUCCESS;
    content: GetContentResponse;
}

export interface GetPreviewContentFailed {
    type: typeof GET_PREVIEW_CONTENT_FAILED;
    err: any;
}
interface IEpisodeData {
    authorFullName: string;
    byteLength: number;
    currentSpot: string;
    dateAdded: Date;
    description: string;
    episodeCanBePlayed: true;
    episodeCreatedAt: Date;
    episodeUpdatedAt: Date;
    hasCompleted: boolean;
    hasPlayed: boolean;
    hlsV3Url: string;
    id: number;
    isPremium: boolean;
    length: string;
    mediumImageUrl: string;
    mpegDashUrl: string;
    number: number;
    onlyAsPackageSubscription: boolean;
    podcastId: number;
    podcastImageUrl: string;
    podcastTitle: string;
    publishDate: Date;
    smallImageUrl: string;
    smoothStreamingUrl: string;
    title: string;
    type: string;
    url: string;
}

export interface IPodcast {
    description: string;
    destinationPath: string;
    id: number;
    imageUrl: string;
    title: string;
    isPremium?: boolean;
    slug?: string;
}

export interface IEpisode {
    dateAdded: string;
    destinationPath: string;
    id: number;
    imageUrl: string;
    isPlayable: boolean;
    isPremium: boolean;
    length: string;
    podcastTitle: string;
    podcastSlug: string;
    title: string;
    streamUrl?: string;
}
