import { memo, ReactElement, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Flex, Box, Text, Modal, ModalOverlay, ModalContent, ModalBody, Slide, useDimensions, Image } from "@chakra-ui/react";
import { useDrag } from "@use-gesture/react";
import { useSpring, config, a } from "react-spring";

import AudioSlider from "../AudioSlider/AudioSlider";
import strings from "@locale/localization";
import { PodmeColor } from "@typings/index";
import { PlayObject } from "@containers/GlobalPlayer/redux/GlobalPlayerTypes";

import "./BigPlayer.scss";
import BigPlayerButtons from "./components/BigPlayerButtons";
import { useDeviceOnline } from "../../../app/hooks";

interface Props {
    isOpen: boolean;
    playObject: PlayObject;
    isPlaying: boolean;
    isLoading: boolean;
    playPosition: number;
    currentTime: string;
    totalTime: string;
    isDisabled: boolean;
    play: (evt: any) => void;
    setPlayPosition: (value: number) => any;
    skipBackwards: () => void;
    skipForwards: () => void;
    onClose: () => any;
}

function BigPlayer({
    isOpen,
    playObject,
    isPlaying,
    isLoading,
    playPosition,
    currentTime,
    totalTime,
    isDisabled,
    play,
    setPlayPosition,
    skipBackwards,
    skipForwards,
    onClose,
}: Props): ReactElement {
    const { locale, podcast } = strings.routes;

    const modalWrapperRef = useRef<HTMLDivElement>(null);
    const modalWrapperDimension = useDimensions(modalWrapperRef, true);
    const [{ y }, api]: [{ y: any }, any] = useSpring(() => ({ y: 0 }));
    const isOnline = useDeviceOnline();
    const [isOpenSlider, setIsOpenSlider] = useState(false);
    const [currentTimeStamp, setCurrentTimeStamp] = useState<string>(currentTime);
    const [audioTrackChanging, setAudioTrackChanging] = useState(false);

    const display: any = y.to((py: any) => (py < window.innerHeight * 1.2 ? "flex" : "none"));

    useEffect(() => {
        if (isOpen) {
            open();
            setIsOpenSlider(true);
        } else setIsOpenSlider(false);
    }, [isOpen]);

    useEffect(() => {
        if (!audioTrackChanging) setCurrentTimeStamp(currentTime);
    }, [currentTime]);

    useEffect(() => {
        if (isOpen && modalWrapperRef.current) {
            modalWrapperRef.current.style.transform = "translateY(0) !important";
        }
    }, [modalWrapperRef.current, isOpen]);

    useEffect(() => {
        if (isOpen && modalWrapperRef.current) {
            modalWrapperRef.current.scrollTo({ top: 0, behavior: "smooth" });
        }
    }, [playObject.title]);

    const handleModalClose = () => {
        setIsOpenSlider(false);
        setTimeout(() => {
            onClose();
        }, 300);
    };

    const bindDrag = useDrag(
        ({ last, velocity: [, vy], direction: [, dy], movement: [, my], cancel }) => {
            if (my < -70) cancel();

            if (last) {
                my > (modalWrapperDimension?.borderBox?.height || window.innerHeight) * 0.5 || (vy > 1 && dy === 1)
                    ? close(1.5)
                    : open();
            } else api.start({ y: my, immediate: false });
        },
        {
            from: () => [0, y.get()],
            filterTaps: true,
            bounds: { top: 0 },
        }
    );

    const close = (_velocity = 1) => {
        api.start({
            y: modalWrapperDimension?.borderBox.height,
            immediate: false,
            config: { ...config.stiff, velocity: 2 },
        });
        handleModalClose();
    };

    const open = () => {
        api.start({
            y: 0,
            immediate: false,
            config: { ...config.stiff, velocity: 1.5 },
        });
    };

    return (
        <Modal isOpen={isOpen} onClose={handleModalClose}>
            <ModalOverlay zIndex="94" />
            <Slide direction="bottom" in={isOpenSlider} style={{ zIndex: isOnline ? 1406 : 1404, height: "100%" }}>
                <ModalContent
                    ref={modalWrapperRef}
                    backgroundColor="transparent"
                    height="100%"
                    margin="0"
                    css={{
                        "&::-webkit-scrollbar": {
                            display: "none",
                        },
                    }}
                >
                    <ModalBody
                        padding="0"
                        sx={{
                            "* p": {
                                fontFamily: "Garnett, sans-serif",
                            },
                        }}
                    >
                        <a.div
                            {...bindDrag()}
                            style={{
                                display,
                                y,
                                scrollBehavior: "smooth",
                                touchAction: "none",
                                position: "relative",
                                flexFlow: "column",
                                alignItems: "center",
                                gridGap: "1rem",
                                maxHeight: "100vh",
                                height: "100%",
                                backgroundColor: PodmeColor.Woodsmoke,
                                backgroundSize: "250%",
                                backgroundPosition: "top center",
                                borderRadius: "1.5rem 1.5rem 0 0",
                                padding: "0.5rem 1.5rem 2rem",
                            }}
                        >
                            <Flex width="100%" height="100%" flexBasis="20rem" direction="column" flex="1" maxHeight="100vw">
                                <Flex minHeight="3rem" width="100%">
                                    <Box
                                        position="absolute"
                                        width="4rem"
                                        height="0.3rem"
                                        rounded="1rem"
                                        backgroundColor={`${PodmeColor.Silver}20`}
                                        top="0.8rem"
                                        left="50%"
                                        transform="translateX(-50%)"
                                    ></Box>
                                    <Image
                                        src="/assets/images/icons/chevron-down.svg"
                                        onClick={handleModalClose}
                                        width="32px"
                                        position="absolute"
                                        top="1rem"
                                        right="1.25rem"
                                        opacity="50%"
                                        cursor="pointer"
                                        transition="all 100ms ease-in"
                                        _hover={{
                                            opacity: "100%",
                                        }}
                                    />
                                </Flex>
                                <Box
                                    flex="1"
                                    background={`url(${playObject?.imageUrl}) center center / contain no-repeat`}
                                    alt={playObject?.title}
                                    width="100%"
                                    height="100%"
                                    maxHeight="55vh"
                                    borderRadius="1rem"
                                />
                            </Flex>
                            <Flex flexDirection={"column"} width={"100%"} gridGap={"0.5rem"}>
                                <Flex
                                    direction="column"
                                    sx={{
                                        "* p": {
                                            lineHeight: "25.6px",
                                        },
                                        "> p:focus": {
                                            outline: "none",
                                            boxShadow: "none",
                                        },
                                        "& a:focus": {
                                            outline: "none",
                                            boxShadow: "none",
                                        },
                                    }}
                                >
                                    <Text fontWeight={"300"} color={PodmeColor.Silver} onClick={handleModalClose}>
                                        <Link to={`/${locale}/${podcast}/${playObject?.slug}`}>{playObject?.podcastTitle}</Link>
                                    </Text>
                                    <Text color={PodmeColor.SoftWhite} fontWeight={"700"} noOfLines={[2]} onClick={handleModalClose}>
                                        <Link to={`/${locale}/${podcast}/${playObject.slug}/${playObject.id}`}>
                                            {playObject?.title}
                                        </Link>
                                    </Text>
                                </Flex>
                                <Box>
                                    <AudioSlider
                                        value={playPosition}
                                        handleOnChange={(val) => {
                                            setPlayPosition(val);
                                        }}
                                        audioTrackChanging={audioTrackChanging}
                                        color={PodmeColor.Cyan}
                                        setAudioTrackChanging={setAudioTrackChanging}
                                        setCurrentTimeStamp={setCurrentTimeStamp}
                                        totalTime={totalTime}
                                    />
                                    <Flex justifyContent={"space-between"} fontSize={"clamp(0.6rem, calc(2vw + 0.3rem), 0.75rem)"}>
                                        <Box>
                                            <Text color={PodmeColor.SoftWhite} fontWeight={"600"}>
                                                {currentTimeStamp}
                                            </Text>
                                        </Box>
                                        <Text color={PodmeColor.Silver}>{totalTime}</Text>
                                    </Flex>
                                </Box>
                                <BigPlayerButtons
                                    skipBackwards={skipBackwards}
                                    skipForwards={skipForwards}
                                    play={play}
                                    isPlaying={isPlaying}
                                    isLoading={isLoading}
                                    isDisabled={isDisabled}
                                />
                            </Flex>
                        </a.div>
                    </ModalBody>
                </ModalContent>
            </Slide>
        </Modal>
    );
}

export default memo(BigPlayer);
